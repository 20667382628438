<template>
  <main class="m-0 p-0 px-4 px-md-5 w-100 bg-neutral-dark">
    <div class="m-3 d-flex align-items-center flex-wrap">
      <span class="text-neutral-light strong-header-text mr-2">
        {{ $store.state.project.name || "" }}
      </span>
      <v-icon color="#fff" size="30px"> mdi-chevron-right </v-icon>
      <span class="strong-header-text font-weight-300 text-primary-light">
        Project Dashboard
      </span>
      <button
        @click="submit"
        class="ms-auto mt-3 rounded-button bg-blue-2 text-neutral-light"
      >
        <h5 class="my-1 mx-2">Go To Cart</h5>
      </button>
    </div>
    <hr class="text-neutral-light mb-4" />
    <h2 class="font-weight-300 text-primary-light">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores.
    </h2>
    <div class="w-100 dashboard__grid">
      <room-card v-for="room in rooms" :key="room.id" :room="room" />
    </div>
    <cart-loading v-if="showLoading" @loading-done="cartLoadingDone" />
  </main>
</template>

<script>
import RoomCard from "../components/RoomCard";
import CartLoading from "@/components/CartLoading";

export default {
  name: "Dashboard",
  components: {
    CartLoading,
    RoomCard,
  },
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    rooms: function () {
      return this.$store.state.dashboard.rooms.filter(
        (r) => r.name !== "Project"
      );
    },
  },
  methods: {
    submit() {
      this.showLoading = true;
    },
    cartLoadingDone() {
      this.$store.dispatch("dashboard/submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__grid {
    display: grid;
    place-content: center;

    $i: 1;

    $grid-breakpoints: (
      xs: 0,
      s: 1,
      md: 768px,
      xl: 1200px,
      xxl: 1600px,
    );

    @each $name, $value in $grid-breakpoints {
      @media (min-width: $value) {
        grid-template-columns: repeat(($i), minmax(0, 1fr));
        gap: Min((0.75 + (($i - 1) * 0.5)) + rem, 1.5rem);
      }

      $i: $i + 1;
    }
  }
}
</style>
