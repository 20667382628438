<template>
  <div
    class="bg-black card cursor-pointer"
    @click="$router.push({ name: 'Room', params: { id: room.id } })"
  >
    <div
      class="title p-2 px-3 d-flex align-items-center justify-content-around"
    >
      <div class="d-flex justify-content-center me-4" style="width: 35%">
        <div class="icon-rounded-square">
          <img
            :alt="room.typeName"
            :class="[
              'icon',
              room.isSelected ? '' : 'projectSpace__not-selected',
            ]"
            :src="
              require(`../assets/icons/room-types/${room.typeName.replaceAll(
                ' ',
                '-'
              )}.png`)
            "
          />
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center" style="width: 75%">
        <span class="body-2-text text-blue-1 font-weight-500">
          {{ room.name }}
        </span>
        <span class="subtitle-text text-neutral font-weight-300">
          {{ room.typeName }}
        </span>
      </div>
    </div>
    <div class="w-100 icons-row d-flex justify-content-between">
      <div class="icon-box" v-for="system in room.systems" :key="system.id">
        <img
          :class="['small-icon', system.active ? '  active' : '']"
          :alt="system.name"
          :src="require(`../assets/icons/Dashboard/${system.icon}.png`)"
        />
      </div>
    </div>
    <div class="bg-blue-2 footer p-2 d-flex align-items-center">
      <span
        class="font-weight-400 w-50 text-center sep subtitle-text text-neutral cursor-pointer"
      >
        <v-icon color="#fff" size="23px" class="ms-2"> mdi-cog </v-icon>

        SETTINGS
      </span>
      <span
        class="font-weight-400 w-50 text-center subtitle-text text-neutral cursor-pointer"
      >
        <inline-svg
          class="small-icon mx-2"
          :src="require('../assets/icons/Dashboard/duplicate.svg')"
        />
        DUPLICATE
      </span>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "RoomCard",
  components: { InlineSvg },
  props: {
    room: Object,
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 250px;
  border: solid 1px var(--blue-1);
  border-radius: 18px;
}

.title {
  border-bottom: solid 1px var(--blue-1);
  height: 70%;
}

.icon {
  fill: grey;
  overflow: visible;
  width: 45px;
}

.icon-rounded-square {
  border: 2px solid var(--blue-1);
  border-radius: 12%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12.5%; */
}

.icons-row {
  border-bottom: solid 1px var(--blue-1);
}

.icon-box {
  width: 100%;
  height: 40px;
  border-right: solid 1px var(--blue-1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-icon {
  fill: grey;
  width: 19px;
}

.active {
  fill: white !important;
}

.footer {
  height: 50px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.sep {
  border-right: solid 1px white;
}
</style>
